<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import { useDisplay } from "vuetify";
import { configEnum } from "~/config/enum";
import { useAuthStore } from "~/stores/authStore";

const { width } = useDisplay();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

// document: https://platform.text.com/docs/extending-chat-widget/javascript-api#methods
const hideLiveChat = () => {
  if (window.LiveChatWidget) {
    window.LiveChatWidget.call("hide");
  }
};

const checkAndHideLiveChat = () => {
  if (width.value < configEnum.BREAK_POINT_SCREEN) {
    hideLiveChat();
  }
};

onMounted(() => {
  // add window.__lc.asyncInit = true; in nuxt.config.js
  if (window.LiveChatWidget) {
    window.LiveChatWidget.init();
    window.LiveChatWidget.on("visibility_changed", onVisibilityChanged);
  }

  checkAndHideLiveChat();

  if (user.value && window.LiveChatWidget) {
    setCustomData(user.value);
  }
});

watch(user, (newUser) => {
  if (newUser) {
    setCustomData(newUser);
  }
});

function setCustomData(user) {
  window.LiveChatWidget?.call?.("set_customer_email", user.email);
  window.LiveChatWidget?.call?.(
    "set_customer_name",
    `${user.first_name || ""} ${user.last_name || ""}`
  );
}

// event listener: "maximized" | "minimized" | "hidden"
function onVisibilityChanged(data) {
  switch (data.visibility) {
    case "minimized":
      checkAndHideLiveChat();
      break;
  }
}
</script>

<template></template>
